/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */




html{
	height:100%;
	width:100%;
	font-size:62.5%;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);

}

div > *:first-child{
	margin-top:0;
}

div > *{
	&:last-child{
		margin-bottom:0;
	}
}
//
//div > *:last-child,
//div > *:last-child > *:last-child,
//div > *:last-child > *:last-child > *:last-child,
//div > *:last-child > *:last-child > *:last-child > *:last-child,
//div > *:last-child > *:last-child > *:last-child > *:last-child > *:last-child{
//	margin-bottom:0;
//}


img{
	max-width:100%;
	height: auto;
}

body{
	font-family:$font-family;
	line-height:$line-height;
	font-size:$font-size;
	color:$font-color;
}



h1{
	@include headline-1;
}

h2{
	@include headline-2;
}


h3{
	font-size:1em;
	font-weight:600;
	margin:1em 0 1em;
	color:$font-h3-color;

	#footer &{
		color:$footer-font-color;
	}

}

h4,h5,h6{
	font-size:1em;
	font-weight:600;
	margin:1em 0 1em;

	#footer &{
		color:$footer-font-color;
	}

}


p{
	font-weight:400;
	margin:1em 0;

	#footer &{
		color:$footer-font-color;
		margin:0;
		line-height:1;
	}


}

strong{
	font-weight:600;
}

a{

	text-decoration:underline;
	color:$font-color;

	&.more-link{
		display:inline-block;
		padding-left:0.3em;
		text-decoration:none;
		&:before{
			font-family:'FontAwesome';
			display:inline-block;
			content:'\f0da';
			padding-right: 0.5em;
		}

		.view-mode-one_pager &{
			margin-top:1em;
			background-color: $button-background;
			color:$button-font-color;
			padding: 0.25em 0.75em;
			border-radius: 2px;
			text-decoration:none;

			&:hover{
				background-color:darken($color-primary,10%);
			}
		}
	}

	&:hover{
		text-decoration:underline;
		color:$color-primary;
	}

	&.anker{
		display:block;
		clear:both;
		@include clearfix;
	}

	#footer &{
		color:$footer-font-color;
	}
}

ul,ol{
	margin:1em 0;
	padding-left:1.3em;

	li{
		ul{
			margin:0;
		}
	}

	.block &{
		padding:0;
		margin:0;

		li{
			list-style:none;

			a{
				text-decoration:none;
			}
		}

	}
}

ol{
	list-style:decimal;
}
ul{
	list-style:square;

	&.pager{
		width:75%;
		margin:2em auto;
		text-align:center;

		li{
			display:inline;
			list-style:none;
			padding:0 0.5em;

		}
	}
}

.element-invisible {
	width: 1px;
	opacity: 0;
}

.description{
	font-style:italic;
}

em,i{
	font-style:italic;
}

sup{
	vertical-align: super;
	font-size: smaller;
}

sub{
	vertical-align: sub;
	font-size: smaller;
}


q {
	&:lang(de)    { quotes:"\201E" "\201C" "\201A" "\2018"; }
	&:lang(de-DE) { quotes:"\00BB" "\00AB" "\203A" "\2039"; }
	&:lang(de-CH) { quotes:"\00AB" "\00BB" "\2039" "\203A"; }

	&:before{
		content: open-quote;
		color:$font-color;
	}

	&:after{
		content: close-quote;
		color:$font-color;
	}
}

blockquote{
	padding-left:2em;
}

small{
}

mark{
	background:$color-secondary;
}

code{
	font-family:monospace;
}

table{

	caption{
		font-weight:bold;
		padding:0.5em 0;
	}

	tr{
		td,th{
			padding:0.5em;

			&:first-child{
				padding-left:0;
			}
		}
	}
}

.label-inline{
	display:inline-block;
	min-width:8em;
}

.more-help-link{
	a{
	}
}

.more-link{
	display:inline-block;
	a{

		&:after{
			content:' »';
		}
	}
}

.field-name-field-e-mail{
	margin-top:1em;
}

.views-row{
	margin:2em 0;

	&.views-row-last{
		margin-bottom:0;
	}
}


#page{
	overflow:hidden;
}

.file{
	img{
		display:none;
	}

	a[type*='pdf']{
		&:before{
			font-family:'FontAwesome';
			content:'\f1c1';
			display:inline-block;
			padding-right:0.3em;
		}
	}
}



.messages{
	padding:$page-gutter;

	color:#fff;

	&.error{
		background-color:$color_error;
	}

	&.warning{
		background-color:$color_warning;
	}

	&.status{
		background-color:$color_status;
	}

	a{
		color:#fff;
	}
}

#admin-menu{
	ul{
		li{
		}
	}
}

#main{

	img{
		max-width:100%;
		height:auto;
	}

	.downloads{

		margin:1em 0;

		.file{
			display:block;

			img{
				position:relative;
				top:0.2em;
			}

			a{
				text-decoration:none;
			}
		}
	}



	.view-aktuelles{
		h3{
			margin:0;
			a{
				font-weight:600;
				text-decoration:none;

			}
		}

		a{
			&.more-link{
				display:inline-block;
				margin-left:0.3em;
				text-decoration:none;
				&:hover{
					text-decoration:underline;
				}

			}
		}

		p{
			margin:0;
		}

		.views-row{
			margin:1.5em 0;
		}
	}

	aside.col-2{
		border-top-width:1px;
		border-style:solid;

		h2{
			font-size:2.4em;
		}
	}
}

#footer{

	h2{
		margin-bottom:0.5em;
	}

	p{

	}

	.block{

		&:first-child{
			padding-top:0;
		}

		&:last-child{
			padding-bottom:0;
		}
	}

	#block-block-3{
		a{
			float:left;
			width:25%;
			text-decoration:none;
			img{
				width:auto;
			}
		}
	}
}

@include breakpoint($breakpoint_medium){
  #logo{
    padding:$page-gutter;
  }

  #main{
    #content{
      padding:$page-gutter;
    }
  }

  aside{
    padding:$page-gutter 0;


    .block{
      padding:0 $page-gutter;
      margin: $page-gutter 0;
    }

    #block-block-2,
    #block-block-7 {
      margin-top:0-$page-gutter;
      padding:$page-gutter;
    }
  }


}

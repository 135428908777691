
@include breakpoint($breakpoint_mobile){

  @include layout(12,1/2){

    .view-mode-teaser{

      &.has-left{
        .ds-left{
          @include grid-span(4,1);
          @include clearfix;

        }

        .ds-right{
          @include grid-span(8,5);
          @include clearfix;

          h2{
            a{
              text-decoration:none;
            }
          }

          .label-inline{
            display:inline-block;
            min-width:8em;
          }

          .field-name-node-link{
            margin-top:1em;
          }
        }
      }


    }

  }
}

@charset "UTF-8";
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome/fontawesome-webfont.eot?v=4.2.0");
  src: url("../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.2.0") format("embedded-opentype"), url("../fonts/fontawesome/fontawesome-webfont.woff?v=4.2.0") format("woff"), url("../fonts/fontawesome/fontawesome-webfont.ttf?v=4.2.0") format("truetype"), url("../fonts/fontawesome/fontawesome-webfont.svg?v=4.2.0#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */
html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

div > *:first-child {
  margin-top: 0; }

div > *:last-child {
  margin-bottom: 0; }

img {
  max-width: 100%;
  height: auto; }

body {
  font-family: "Arial";
  line-height: 1.5;
  font-size: 1.4em;
  color: #333333; }

h1 {
  font-weight: 400;
  font-size: 1.25em;
  margin: 0em 0 1em;
  color: #3a4496;
  font-weight: bold;
  position: relative; }
  h1:before {
    display: inline-block;
    content: '';
    color: #3a4496;
    font-size: 1.5em;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-bottom: 4px;
    margin-right: 10px;
    background-color: #3a4496; }
  @media (min-width: 770px) {
    h1 {
      font-size: 1.25em; } }

h2 {
  font-size: 1.25em;
  font-weight: 400;
  margin: 1.5em 0 1em;
  color: #3a4496;
  font-weight: normal; }
  h2.block-title {
    position: relative;
    font-weight: normal;
    font-size: 1em; }
    h2.block-title:before {
      display: inline-block;
      content: '';
      color: #3a4496;
      font-size: 1.5em;
      vertical-align: middle;
      width: 11px;
      height: 11px;
      margin-bottom: 3px;
      margin-right: 6px;
      background-color: #3a4496; }
    #footer h2.block-title {
      font-size: 1em;
      font-weight: bold;
      color: #fff; }
  .node-immobilie.node-teaser h2 {
    font-size: 1em;
    font-weight: bold; }
    .node-immobilie.node-teaser h2 a {
      color: #3a4496; }
  @media (min-width: 770px) {
    h2 {
      font-size: 1.25em; } }

h3 {
  font-size: 1em;
  font-weight: 600;
  margin: 1em 0 1em;
  color: #3a4496; }
  #footer h3 {
    color: #fff; }

h4, h5, h6 {
  font-size: 1em;
  font-weight: 600;
  margin: 1em 0 1em; }
  #footer h4, #footer h5, #footer h6 {
    color: #fff; }

p {
  font-weight: 400;
  margin: 1em 0; }
  #footer p {
    color: #fff;
    margin: 0;
    line-height: 1; }

strong {
  font-weight: 600; }

a {
  text-decoration: underline;
  color: #333333; }
  a.more-link {
    display: inline-block;
    padding-left: 0.3em;
    text-decoration: none; }
    a.more-link:before {
      font-family: 'FontAwesome';
      display: inline-block;
      content: '\f0da';
      padding-right: 0.5em; }
    .view-mode-one_pager a.more-link {
      margin-top: 1em;
      background-color: #3a4496;
      color: #fff;
      padding: 0.25em 0.75em;
      border-radius: 2px;
      text-decoration: none; }
      .view-mode-one_pager a.more-link:hover {
        background-color: #2c3371; }
  a:hover {
    text-decoration: underline;
    color: #3a4496; }
  a.anker {
    display: block;
    clear: both;
    overflow: hidden;
    *zoom: 1; }
  #footer a {
    color: #fff; }

ul, ol {
  margin: 1em 0;
  padding-left: 1.3em; }
  ul li ul, ol li ul {
    margin: 0; }
  .block ul, .block ol {
    padding: 0;
    margin: 0; }
    .block ul li, .block ol li {
      list-style: none; }
      .block ul li a, .block ol li a {
        text-decoration: none; }

ol {
  list-style: decimal; }

ul {
  list-style: square; }
  ul.pager {
    width: 75%;
    margin: 2em auto;
    text-align: center; }
    ul.pager li {
      display: inline;
      list-style: none;
      padding: 0 0.5em; }

.element-invisible {
  width: 1px;
  opacity: 0; }

.description {
  font-style: italic; }

em, i {
  font-style: italic; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  vertical-align: sub;
  font-size: smaller; }

q:lang(de) {
  quotes: "\201E" "\201C" "\201A" "\2018"; }

q:lang(de-DE) {
  quotes: "\00BB" "\00AB" "\203A" "\2039"; }

q:lang(de-CH) {
  quotes: "\00AB" "\00BB" "\2039" "\203A"; }

q:before {
  content: open-quote;
  color: #333333; }

q:after {
  content: close-quote;
  color: #333333; }

blockquote {
  padding-left: 2em; }

mark {
  background: #A8BFE6; }

code {
  font-family: monospace; }

table caption {
  font-weight: bold;
  padding: 0.5em 0; }

table tr td, table tr th {
  padding: 0.5em; }
  table tr td:first-child, table tr th:first-child {
    padding-left: 0; }

.label-inline {
  display: inline-block;
  min-width: 8em; }

.more-link {
  display: inline-block; }
  .more-link a:after {
    content: ' »'; }

.field-name-field-e-mail {
  margin-top: 1em; }

.views-row {
  margin: 2em 0; }
  .views-row.views-row-last {
    margin-bottom: 0; }

#page {
  overflow: hidden; }

.file img {
  display: none; }

.file a[type*='pdf']:before {
  font-family: 'FontAwesome';
  content: '\f1c1';
  display: inline-block;
  padding-right: 0.3em; }

.messages {
  padding: 2em;
  color: #fff; }
  .messages.error {
    background-color: #9D1309; }
  .messages.warning {
    background-color: #B8860B; }
  .messages.status {
    background-color: #006400; }
  .messages a {
    color: #fff; }

#main img {
  max-width: 100%;
  height: auto; }

#main .downloads {
  margin: 1em 0; }
  #main .downloads .file {
    display: block; }
    #main .downloads .file img {
      position: relative;
      top: 0.2em; }
    #main .downloads .file a {
      text-decoration: none; }

#main .view-aktuelles h3 {
  margin: 0; }
  #main .view-aktuelles h3 a {
    font-weight: 600;
    text-decoration: none; }

#main .view-aktuelles a.more-link {
  display: inline-block;
  margin-left: 0.3em;
  text-decoration: none; }
  #main .view-aktuelles a.more-link:hover {
    text-decoration: underline; }

#main .view-aktuelles p {
  margin: 0; }

#main .view-aktuelles .views-row {
  margin: 1.5em 0; }

#main aside.col-2 {
  border-top-width: 1px;
  border-style: solid; }
  #main aside.col-2 h2 {
    font-size: 2.4em; }

#footer h2 {
  margin-bottom: 0.5em; }

#footer .block:first-child {
  padding-top: 0; }

#footer .block:last-child {
  padding-bottom: 0; }

#footer #block-block-3 a {
  float: left;
  width: 25%;
  text-decoration: none; }
  #footer #block-block-3 a img {
    width: auto; }

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

body {
  background-color: #1d224b;
  clear: both;
  background-image: -owg-linear-gradient(to bottom right, #1d224b, #37418f);
  background-image: -webkit-linear-gradient(to bottom right, #1d224b, #37418f);
  background-image: -moz-linear-gradient(to bottom right, #1d224b, #37418f);
  background-image: -o-linear-gradient(to bottom right, #1d224b, #37418f);
  background-image: linear-gradient(to bottom right, #1d224b, #37418f);
  background-attachment: fixed; }

#top {
  position: relative; }

#page {
  padding: 0;
  margin: 0 auto;
  max-width: 1280px; }

#header {
  background-color: #fff; }

#logo {
  padding: 2em 1em;
  background: #fff;
  line-height: 0; }
  #logo img {
    width: 150px; }

.nav-fixed #nav-main-outer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 350; }
  .nav-fixed #nav-main-outer #nav-main-inner {
    padding: 0;
    max-width: 1280px;
    margin: 0 auto; }

#header {
  overflow: hidden;
  *zoom: 1;
  position: relative; }

#main {
  overflow: hidden;
  *zoom: 1;
  background-color: #fff;
  padding: 0; }
  #main .main-inner {
    max-width: 1280px;
    padding: 2em 0 0;
    margin: 0 auto;
    overflow: hidden;
    *zoom: 1; }
  #main #content {
    padding: 1em; }
  #main #content + aside {
    margin-top: 2em; }

aside {
  padding: 1em 0; }
  aside .block {
    margin: 1em 0;
    padding: 0 1em; }
    aside .block.first {
      margin-top: 0; }
  aside#right {
    background-color: #f5f6fa; }
  aside #block-block-2,
  aside #block-block-7 {
    background-color: #ebecf5;
    margin-top: -1em;
    padding: 1em; }
    aside #block-block-2 h2,
    aside #block-block-7 h2 {
      font-size: 1em;
      font-weight: bold;
      margin: 1.5em 0 1em;
      color: #3a4496; }
      aside #block-block-2 h2:first-child,
      aside #block-block-7 h2:first-child {
        margin-top: 0; }
      @media (min-width: 770px) {
        aside #block-block-2 h2,
        aside #block-block-7 h2 {
          font-size: 1em; } }

#footer {
  padding: 1em 1em;
  background-color: #3a4496; }

@media (min-width: 670px) {
  body {
    clear: none; }
  #top {
    position: relative; }
  #header {
    position: relative;
    padding: 0;
    overflow: hidden;
    *zoom: 1; }
    #header ul {
      padding: 0;
      margin: 0; }
  .one-sidebar.sidebar-first #main h1.page__title {
    width: 66.66667%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    overflow: hidden;
    *zoom: 1; }
  .two-sidebars #main h1.page__title {
    width: 33.33333%;
    float: left;
    margin-right: -100%;
    margin-left: 33.33333%;
    clear: none;
    overflow: hidden;
    *zoom: 1; }
  #main #content {
    overflow: hidden;
    *zoom: 1; }
    .no-sidebars #main #content {
      width: 100%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none;
      clear: left; }
    .one-sidebar.sidebar-first #main #content {
      width: 66.66667%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none; }
    .one-sidebar.sidebar-second #main #content {
      width: 66.66667%;
      float: left;
      margin-right: -100%;
      margin-left: 0;
      clear: none; }
    .two-sidebars #main #content {
      width: 33.33333%;
      float: left;
      margin-right: -100%;
      margin-left: 33.33333%;
      clear: none; }
    #main #content + aside {
      margin-top: 0; }
  #main aside#left {
    width: 33.33333%;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    clear: none;
    clear: left; }
  #main aside#right {
    width: 33.33333%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none; }
  #footer {
    overflow: hidden;
    *zoom: 1;
    padding: 1em 2em;
    background-color: #3a4496; }
    #footer .block {
      width: 50%;
      clear: right;
      float: left;
      margin-left: 0;
      margin-right: 0%; } }

@media (min-width: 770px) {
  #logo {
    padding: 2em; }
  #main #content {
    padding: 2em; }
  aside {
    padding: 2em 0; }
    aside .block {
      padding: 0 2em;
      margin: 2em 0; }
    aside #block-block-2,
    aside #block-block-7 {
      margin-top: -2em;
      padding: 2em; } }

nav#nav-main {
  width: 100%;
  height: 3.1em;
  overflow: hidden;
  padding: 0; }
  nav#nav-main ul.menu,
  nav#nav-main ul.menu-link {
    position: relative;
    padding: 0;
    margin: 0;
    line-height: 1;
    text-align: left; }
    nav#nav-main ul.menu li,
    nav#nav-main ul.menu-link li {
      float: left;
      position: static;
      width: 100%;
      line-height: 1;
      border-bottom-width: 1px;
      border-style: solid;
      list-style: none;
      background: #3a4496;
      border-color: #3a4496; }
      nav#nav-main ul.menu li.expanded > a,
      nav#nav-main ul.menu-link li.expanded > a {
        position: relative; }
      nav#nav-main ul.menu li.expanded > a:after,
      nav#nav-main ul.menu-link li.expanded > a:after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: inset 5px;
        border-color: transparent transparent transparent rgba(255, 255, 255, 0.4);
        border-left-style: solid;
        margin-right: 15px;
        margin-top: -4.5px;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 0; }
      nav#nav-main ul.menu li:hover,
      nav#nav-main ul.menu-link li:hover {
        overflow: visible; }
      nav#nav-main ul.menu li a,
      nav#nav-main ul.menu-link li a {
        text-decoration: none;
        padding: 1em 2em;
        font-size: 1.4rem;
        display: block;
        color: #fff;
        white-space: nowrap; }
        nav#nav-main ul.menu li a:hover, nav#nav-main ul.menu li a.active,
        nav#nav-main ul.menu-link li a:hover,
        nav#nav-main ul.menu-link li a.active {
          background-color: #2c3371;
          color: #fff; }
      nav#nav-main ul.menu li ul,
      nav#nav-main ul.menu-link li ul {
        position: absolute;
        z-index: 99;
        left: 100%;
        top: 4.1rem;
        width: 100%; }
        nav#nav-main ul.menu li ul li,
        nav#nav-main ul.menu-link li ul li {
          float: none;
          padding: 0;
          display: block; }
          nav#nav-main ul.menu li ul li ul,
          nav#nav-main ul.menu-link li ul li ul {
            top: 0; }
  nav#nav-main ul.menu-link {
    display: block; }
    nav#nav-main ul.menu-link li {
      border-bottom-width: 0px;
      width: 100%; }
      nav#nav-main ul.menu-link li a {
        position: relative;
        text-align: right;
        padding-right: 2.5em;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
        nav#nav-main ul.menu-link li a:after {
          position: absolute;
          font-family: "FontAwesome";
          content: "\f0c9";
          right: 1rem;
          font-size: 1.5em;
          -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
          top: 10px; }

@media (min-width: 770px) {
  body:not(.menu-mobile) nav#nav-main {
    height: auto;
    overflow: visible !important;
    padding: 0;
    background: #3a4496; }
    body:not(.menu-mobile) nav#nav-main ul.menu-link {
      display: none; }
    body:not(.menu-mobile) nav#nav-main div > ul.menu {
      text-align: left;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between; }
      body:not(.menu-mobile) nav#nav-main div > ul.menu li {
        -webkit-flex-grow: 1;
        flex-grow: 1;
        text-align: center; }
      body:not(.menu-mobile) nav#nav-main div > ul.menu li.last a {
        padding-right: 2em; }
      body:not(.menu-mobile) nav#nav-main div > ul.menu > li:nth-child(1) a {
        padding: 1em 2em;
        padding-left: 2em; }
      body:not(.menu-mobile) nav#nav-main div > ul.menu > li:nth-child(1) li a {
        padding: 0.5em 1em; }
    body:not(.menu-mobile) nav#nav-main ul.menu {
      position: static;
      padding: 0;
      text-align: right; }
      body:not(.menu-mobile) nav#nav-main ul.menu li {
        position: relative;
        width: auto;
        border-width: 0;
        background: none;
        float: none;
        display: inline-block;
        vertical-align: top; }
        body:not(.menu-mobile) nav#nav-main ul.menu li.mobile {
          display: none; }
        body:not(.menu-mobile) nav#nav-main ul.menu li.last > a {
          padding-right: 0; }
        body:not(.menu-mobile) nav#nav-main ul.menu li.expanded {
          overflow: hidden; }
          body:not(.menu-mobile) nav#nav-main ul.menu li.expanded:hover {
            overflow: visible; }
          body:not(.menu-mobile) nav#nav-main ul.menu li.expanded a {
            position: static; }
        body:not(.menu-mobile) nav#nav-main ul.menu li a {
          text-decoration: none;
          padding: 1em 1.5em;
          line-height: 1;
          font-size: 1.4rem;
          display: block;
          color: #fff; }
          body:not(.menu-mobile) nav#nav-main ul.menu li a.active, body:not(.menu-mobile) nav#nav-main ul.menu li a:hover {
            background-color: #2c3371; }
        body:not(.menu-mobile) nav#nav-main ul.menu li.expanded a:after {
          content: none; }
        body:not(.menu-mobile) nav#nav-main ul.menu li ul {
          position: absolute;
          min-width: 100%;
          z-index: 300;
          width: auto;
          background: #3a4496;
          text-align: left;
          left: auto;
          top: auto; }
          body:not(.menu-mobile) nav#nav-main ul.menu li ul li, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last {
            float: none;
            padding: 0;
            display: block;
            background-color: #3a4496;
            text-align: left; }
            body:not(.menu-mobile) nav#nav-main ul.menu li ul li a, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last a {
              line-height: 2;
              background-color: #3a4496;
              color: #FFF;
              padding: 0.5em 1em; }
              body:not(.menu-mobile) nav#nav-main ul.menu li ul li a.active, body:not(.menu-mobile) nav#nav-main ul.menu li ul li a:hover, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last a.active, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last a:hover {
                background-color: #2c3371;
                color: #FFF; }
            body:not(.menu-mobile) nav#nav-main ul.menu li ul li.active a, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last.active a {
              background-color: #2c3371;
              color: #FFF; }
            body:not(.menu-mobile) nav#nav-main ul.menu li ul li ul, body:not(.menu-mobile) nav#nav-main ul.menu li ul li.last ul {
              right: 100%;
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              padding: 0; }
        body:not(.menu-mobile) nav#nav-main ul.menu li.last > ul {
          right: 0; } }

@media (min-width: 770px) {
  #header.fixed {
    position: fixed;
    z-index: 200; }
  nav#nav-main {
    position: relative;
    width: 100%;
    overflow: visible;
    background-color: #3a4496;
    height: auto;
    overflow: visible !important;
    padding: 0; }
    nav#nav-main ul.menu-link {
      display: none; }
    nav#nav-main div > ul.menu > li:nth-child(1) li a {
      padding: 0.5em 1em; }
    nav#nav-main ul.menu {
      position: static;
      padding: 0;
      text-align: right; }
      nav#nav-main ul.menu li {
        position: relative;
        width: auto;
        border-width: 0;
        background: none;
        float: none;
        vertical-align: top; }
        nav#nav-main ul.menu li.mobile {
          display: none; }
        nav#nav-main ul.menu li.last > a {
          padding-right: 0; }
        nav#nav-main ul.menu li.expanded {
          overflow: hidden; }
          nav#nav-main ul.menu li.expanded:hover {
            overflow: visible; }
        nav#nav-main ul.menu li a {
          text-decoration: none;
          padding: 1em 1.5em;
          line-height: 1;
          font-size: 1.4rem;
          display: block;
          color: #fff; }
          nav#nav-main ul.menu li a.active, nav#nav-main ul.menu li a:hover {
            color: #fff; }
        nav#nav-main ul.menu li.expanded a:after {
          content: none; }
        nav#nav-main ul.menu li ul {
          position: absolute;
          z-index: 300;
          width: auto;
          background: #3a4496;
          text-align: left;
          left: auto;
          top: auto; }
          nav#nav-main ul.menu li ul li, nav#nav-main ul.menu li ul li.last {
            float: none;
            padding: 0;
            display: block;
            background-color: #3a4496; }
            nav#nav-main ul.menu li ul li a, nav#nav-main ul.menu li ul li.last a {
              line-height: 2;
              background-color: #3a4496;
              color: #FFF;
              padding: 0.5em 1em; }
              nav#nav-main ul.menu li ul li a.active, nav#nav-main ul.menu li ul li a:hover, nav#nav-main ul.menu li ul li.last a.active, nav#nav-main ul.menu li ul li.last a:hover {
                background-color: #2c3371;
                color: #FFF; }
            nav#nav-main ul.menu li ul li.active a, nav#nav-main ul.menu li ul li.last.active a {
              background-color: #2c3371;
              color: #FFF; }
            nav#nav-main ul.menu li ul li ul, nav#nav-main ul.menu li ul li.last ul {
              right: 100%;
              width: 100%;
              display: block;
              position: absolute;
              top: 0;
              padding: 0; }
        nav#nav-main ul.menu li.last > ul {
          right: 0; }
    .medium-view nav#nav-main {
      position: static;
      width: 100%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none;
      padding: 0;
      background: #3a4496; }
      .medium-view nav#nav-main div > ul.menu {
        text-align: left;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between; }
        .medium-view nav#nav-main div > ul.menu li.last a {
          padding-right: 2em; }
        .medium-view nav#nav-main div > ul.menu li:nth-child(1) a {
          padding: 1em 2em; }
      .medium-view nav#nav-main ul.menu li a {
        color: #fff; }
        .medium-view nav#nav-main ul.menu li a.active, .medium-view nav#nav-main ul.menu li a:hover {
          color: #fff;
          background-color: #2c3371; } }

#block-menu-menu-footer-menu ul {
  padding: 0;
  margin: 1em 0 0;
  text-align: left;
  line-height: 1; }
  @media (min-width: 770px) {
    #block-menu-menu-footer-menu ul {
      text-align: right;
      margin: 0; } }
  #block-menu-menu-footer-menu ul li {
    display: inline-block;
    padding: 0;
    list-style: none;
    padding: 0; }
    #block-menu-menu-footer-menu ul li:before {
      content: '·';
      padding: 0 0.3em 0 0em;
      color: #fff; }
    #block-menu-menu-footer-menu ul li a {
      text-decoration: none; }
      #block-menu-menu-footer-menu ul li a:hover {
        text-decoration: underline; }
    #block-menu-menu-footer-menu ul li.first:before {
      display: none; }
    #block-menu-menu-footer-menu ul li.last {
      padding-right: 0; }

form {
  margin: 2em 0; }
  form input[type=text],
  form input[type=email],
  form input[type=password],
  form input[type=number],
  form .form-textarea-wrapper textarea {
    border-width: 1px;
    border-style: solid;
    font-size: 1em;
    width: 100%;
    padding: 0.3em 0.2em;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: "Arial";
    webkit-appearance: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    border-color: #cccccc;
    background-color: white; }
    form input[type=text]:focus,
    form input[type=email]:focus,
    form input[type=password]:focus,
    form input[type=number]:focus,
    form .form-textarea-wrapper textarea:focus {
      border-color: #000;
      outline: none; }
    form input[type=text]#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit,
    form input[type=email]#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit,
    form input[type=password]#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit,
    form input[type=number]#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit,
    form .form-textarea-wrapper textarea#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit {
      width: 100px; }
    @media (min-width: 770px) {
      form input[type=text],
      form input[type=email],
      form input[type=password],
      form input[type=number],
      form .form-textarea-wrapper textarea {
        max-width: 30em; } }
  form input[readonly=readonly] {
    border: 0;
    padding-left: 0;
    margin-left: 0; }
    form input[readonly=readonly]#edit-submitted-objekttitel {
      max-width: 100%;
      width: 100%; }
  form textarea {
    min-width: 100%; }
  form .form-item {
    margin: 1em 0; }
    form .form-item .form-item {
      margin: 0; }
  form .webform-component-markup {
    margin: 1em 0 0.5em; }
  form .form-item-autocomplete input[type=text] {
    background-image: url("/misc/throbber.gif");
    background-position: 100% 8px;
    background-repeat: no-repeat; }
    form .form-item-autocomplete input[type=text].throbbing {
      background-position: 100% -12px; }
  form input[type=text],
  form input[type=email],
  form label {
    line-height: 1.5; }
  form input[type=submit] {
    cursor: pointer;
    font-family: "Arial";
    font-size: 1em;
    position: relative;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    padding: 0.5em 1em;
    margin: 0% 0% 0% 0;
    line-height: 1.5;
    border: 0;
    -webkit-appearance: none;
    background-color: #3a4496;
    border-color: #1e234c;
    color: #fff;
    -webkit-transition: background-color;
    -moz-transition: background-color;
    -o-transition: background-color;
    transition: background-color; }
    form input[type=submit]:hover {
      background-color: #2c3371; }
  form label {
    display: block;
    width: 100%;
    margin-bottom: 0.5em; }
  form #edit-submitted-objekttitel {
    font-weight: bold; }
  form #webform-component-objektnummer label {
    margin-right: 0; }
  form #webform-component-suchkriterien-etage-objekt-mit-fahrstuhl *,
  form #webform-component-suchkriterien-anzahl-der-zimmer *,
  form #webform-component-suchkriterien-grosse-der-wohnung *,
  form #webform-component-suchkriterien-etage-objekt-ohne-fahrstuhl *,
  form #webform-component-suchkriterien-kaufpreis * {
    display: inline-block;
    width: auto; }
  form #webform-component-suchkriterien-etage-objekt-mit-fahrstuhl input[type="number"],
  form #webform-component-suchkriterien-anzahl-der-zimmer input[type="number"],
  form #webform-component-suchkriterien-grosse-der-wohnung input[type="number"],
  form #webform-component-suchkriterien-etage-objekt-ohne-fahrstuhl input[type="number"],
  form #webform-component-suchkriterien-kaufpreis input[type="number"] {
    max-width: 6em; }
  form #webform-component-suchkriterien-etage-objekt-mit-fahrstuhl .webform-component-markup,
  form #webform-component-suchkriterien-anzahl-der-zimmer .webform-component-markup,
  form #webform-component-suchkriterien-grosse-der-wohnung .webform-component-markup,
  form #webform-component-suchkriterien-etage-objekt-ohne-fahrstuhl .webform-component-markup,
  form #webform-component-suchkriterien-kaufpreis .webform-component-markup {
    display: block;
    width: 100%;
    margin: 0;
    margin-bottom: 0.5em; }
    form #webform-component-suchkriterien-etage-objekt-mit-fahrstuhl .webform-component-markup p,
    form #webform-component-suchkriterien-anzahl-der-zimmer .webform-component-markup p,
    form #webform-component-suchkriterien-grosse-der-wohnung .webform-component-markup p,
    form #webform-component-suchkriterien-etage-objekt-ohne-fahrstuhl .webform-component-markup p,
    form #webform-component-suchkriterien-kaufpreis .webform-component-markup p {
      margin: 0; }
  form .form-type-radio,
  form .form-type-checkboxes,
  form .form-type-checkbox {
    display: inline-block; }
    form .form-type-radio label,
    form .form-type-radio .form-radio,
    form .form-type-radio .form-checkbox,
    form .form-type-checkboxes label,
    form .form-type-checkboxes .form-radio,
    form .form-type-checkboxes .form-checkbox,
    form .form-type-checkbox label,
    form .form-type-checkbox .form-radio,
    form .form-type-checkbox .form-checkbox {
      width: auto;
      display: inline-block; }
  form .password-strength {
    float: none;
    margin: 1em 0; }
  form fieldset {
    margin: 2em 0;
    padding: 0;
    border: 0px;
    border-top: 0; }
    form fieldset legend {
      font-size: 1.25em;
      font-weight: 400;
      margin: 1.5em 0 1em;
      color: #3a4496;
      font-weight: normal;
      padding-left: 0;
      margin: 0;
      display: block;
      width: 100%;
      font-size: 1.25em;
      line-height: 1;
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid #cccccc; }
      form fieldset legend.block-title {
        position: relative;
        font-weight: normal;
        font-size: 1em; }
        form fieldset legend.block-title:before {
          display: inline-block;
          content: '';
          color: #3a4496;
          font-size: 1.5em;
          vertical-align: middle;
          width: 11px;
          height: 11px;
          margin-bottom: 3px;
          margin-right: 6px;
          background-color: #3a4496; }
        #footer form fieldset legend.block-title {
          font-size: 1em;
          font-weight: bold;
          color: #fff; }
      .node-immobilie.node-teaser form fieldset legend {
        font-size: 1em;
        font-weight: bold; }
        .node-immobilie.node-teaser form fieldset legend a {
          color: #3a4496; }
      @media (min-width: 770px) {
        form fieldset legend {
          font-size: 1.25em; } }
    form fieldset.collapsible legend {
      border: 0;
      padding: 1em 0;
      border-bottom: 1px solid #404040;
      line-height: 1.5;
      display: block;
      width: 100%; }
    form fieldset .fieldset-description {
      font-style: italic; }
    form fieldset#webform-component-suchkriterien-stadtteile label,
    form fieldset#webform-component-suchkriterien-stadtteile input {
      display: inline-block;
      width: auto; }
    form fieldset#webform-component-suchkriterien-stadtteile .webform-component-markup {
      margin-bottom: 0.5em; }
    form fieldset#webform-component-suchkriterien-stadtteile label {
      width: 5%; }
      @media (min-width: 670px) {
        form fieldset#webform-component-suchkriterien-stadtteile label {
          width: 3%; } }
    form fieldset#webform-component-suchkriterien-stadtteile input {
      width: 80%; }
    form fieldset .form-item:last-child {
      margin-bottom: 0; }
    form fieldset fieldset {
      margin: 1em 0; }
      form fieldset fieldset .form-item {
        margin: 0; }
  form .resizable-textarea {
    min-width: 100%; }
    form .resizable-textarea .grippie {
      display: none; }
  form #edit-actions #edit-cancel {
    display: inline-block;
    background-color: #9D1309;
    color: #fff;
    padding: 0.5em 1em;
    text-decoration: none;
    -webkit-transition: background-color, 300ms, ease-out 1s;
    -webkit-transition-delay: 0s;
    -moz-transition: background-color, 300ms, ease-out 1s;
    -o-transition: background-color, 300ms, ease-out 1s;
    transition: background-color 300ms ease-out 1s false false; }
    form #edit-actions #edit-cancel:hover {
      background-color: #6d0d06; }
  form .vertical-tabs fieldset {
    padding: 1em; }
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-select,
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--datum-egal,
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--datum,
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit,
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-egal {
    display: inline-block;
    width: 60%;
    margin-bottom: 0; }
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--datum-egal,
  form#webform-client-form-15 #webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-egal {
    width: 20%;
    margin-right: 1em; }
  form#webform-client-form-15 .webform-component-markup {
    font-size: 1.25em;
    font-weight: 400;
    margin: 1.5em 0 1em;
    color: #3a4496;
    font-weight: normal;
    margin: 0;
    margin-top: 2em; }
    form#webform-client-form-15 .webform-component-markup.block-title {
      position: relative;
      font-weight: normal;
      font-size: 1em; }
      form#webform-client-form-15 .webform-component-markup.block-title:before {
        display: inline-block;
        content: '';
        color: #3a4496;
        font-size: 1.5em;
        vertical-align: middle;
        width: 11px;
        height: 11px;
        margin-bottom: 3px;
        margin-right: 6px;
        background-color: #3a4496; }
      #footer form#webform-client-form-15 .webform-component-markup.block-title {
        font-size: 1em;
        font-weight: bold;
        color: #fff; }
    .node-immobilie.node-teaser form#webform-client-form-15 .webform-component-markup {
      font-size: 1em;
      font-weight: bold; }
      .node-immobilie.node-teaser form#webform-client-form-15 .webform-component-markup a {
        color: #3a4496; }
    @media (min-width: 770px) {
      form#webform-client-form-15 .webform-component-markup {
        font-size: 1.25em; } }

.node-type-immobilie .geofieldMap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden; }

.leaflet-popup-content-wrapper {
  border-radius: 0; }
  .leaflet-popup-content-wrapper .leaflet-popup-content {
    margin: 0;
    padding: 1em; }

.leaflet-popup-tip-container {
  top: -10px; }

.leaflet-popup-tip {
  box-shadow: none; }

.leaflet-popup-close-button {
  text-indent: -9999px; }
  .leaflet-popup-close-button:after {
    display: inline-block;
    content: '\2A2F';
    font-size: 1.75em;
    line-height: 0;
    text-indent: 9980px; }

.leaflet-tile-container {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%); }

.node-type-immobilie .field-name-field-geofield-kurz,
.node-type-immobilie .field-name-field-geofield {
  position: relative;
  padding-bottom: 56%;
  height: 0;
  overflow: hidden; }

#block-current-search-standard {
  margin-bottom: 0; }
  #block-current-search-standard .current-search-item-text {
    display: none; }
  #block-current-search-standard + #block-block-3 {
    margin-top: 0; }

#block-block-3 {
  margin-top: 0; }

.block-facetapi ul,
.block-current-search ul {
  padding: 0; }
  .block-facetapi ul li,
  .block-current-search ul li {
    list-style: none; }
    .block-facetapi ul li a,
    .block-current-search ul li a {
      text-decoration: none; }
    .block-facetapi ul li span.reset:after,
    .block-current-search ul li span.reset:after {
      display: inline;
      content: '\2A2F';
      font-size: 2em;
      line-height: 0; }
    .block-facetapi ul li .field_kaltmiete:after,
    .block-facetapi ul li .field_kaufpreis:after,
    .block-current-search ul li .field_kaltmiete:after,
    .block-current-search ul li .field_kaufpreis:after {
      /*           display:inline-block;
content:'€';
margin-left:0.3em; */ }
    .block-facetapi ul li .field_wohnflaeche:after,
    .block-current-search ul li .field_wohnflaeche:after {
      /*           display:inline-block;
content:'m²';
margin-left:0.3em; */ }
    .block-facetapi ul li .element-invisible,
    .block-current-search ul li .element-invisible {
      display: none; }

.block-facetapi #search-api-ranges-field_wohnflaeche .form-item-range-to:after,
.block-current-search #search-api-ranges-field_wohnflaeche .form-item-range-to:after {
  display: inline-block;
  content: 'm²'; }

.block-facetapi #search-api-ranges-field_kaltmiete .form-item-range-to:after,
.block-facetapi #search-api-ranges-field_kaufpreis .form-item-range-to:after,
.block-current-search #search-api-ranges-field_kaltmiete .form-item-range-to:after,
.block-current-search #search-api-ranges-field_kaufpreis .form-item-range-to:after {
  display: inline-block;
  content: '€'; }

.view-header .views-row {
  overflow: hidden; }
  .view-header .views-row li {
    display: none; }
    .view-header .views-row li:nth-child(1) {
      display: block; }

.slideshow {
  background-color: #ebecf4;
  line-height: 0; }
  .slideshow img {
    padding: 1em; }
  .group-right .slideshow {
    padding: 0 !important; }
  .slideshow .slideshow-next,
  .slideshow .slideshow-prev {
    position: absolute;
    top: 0;
    height: 100%;
    width: 20%;
    cursor: pointer;
    z-index: 200; }
    .slideshow .slideshow-next img,
    .slideshow .slideshow-prev img {
      display: none; }
      .slideshow .slideshow-next img:nth-child(1),
      .slideshow .slideshow-prev img:nth-child(1) {
        display: block; }
  .slideshow:hover .slideshow-next {
    right: 0;
    background: image-url("hwub-button-arrow-right.png") right 2.5rem center no-repeat;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6;
    background-size: 27px; }
    @media (min-width: 670px) {
      .slideshow:hover .slideshow-next {
        background-size: 40px; } }
  .slideshow:hover .slideshow-prev {
    left: 0;
    background: image-url("hwub-button-arrow-left.png") left 2.5rem center no-repeat;
    background-size: 27px;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
    opacity: 0.6; }
    @media (min-width: 670px) {
      .slideshow:hover .slideshow-prev {
        background-size: 40px; } }
  .slideshow .slideshow-prev:hover,
  .slideshow .slideshow-next:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }

.slideshow-caption {
  background-color: #ebecf4;
  padding: 1em;
  text-align: center; }

.group-thumbs {
  margin: 1em 0 2em;
  padding-bottom: 1em;
  overflow: hidden;
  *zoom: 1; }
  .group-thumbs img {
    line-height: 0;
    margin-bottom: 1em;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
    opacity: 0.75;
    cursor: pointer; }
    .group-thumbs img:nth-child(n) {
      width: 15.09434%;
      clear: right;
      float: left;
      margin-left: 0;
      margin-right: 1.88679%;
      display: block; }
    .group-thumbs img:nth-child(6n+0) {
      width: 15.09434%;
      clear: right;
      float: right;
      margin-right: 0; }
    .group-thumbs img:nth-child(6n+1) {
      width: 15.09434%;
      clear: right;
      float: left;
      margin-left: 0;
      margin-right: 1.88679%;
      clear: both; }
    .group-thumbs img:last-child {
      margin-bottom: 1em; }
    .group-thumbs img:hover, .group-thumbs img.cycle-pager-active {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; }

.search-api-ranges-widget .text-range {
  display: none; }

.search-api-ranges-widget input.form-text {
  border: 0;
  font-size: 1em;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: transparent; }

.search-api-ranges-widget .yui3-g {
  position: relative;
  margin-top: 3em; }
  .search-api-ranges-widget .yui3-g input {
    width: auto; }
  .search-api-ranges-widget .yui3-g label {
    display: none; }
  .search-api-ranges-widget .yui3-g .range-slider-box {
    display: block;
    width: 100%;
    padding: 0 0.6em; }
  .search-api-ranges-widget .yui3-g .range-box-left {
    position: absolute;
    top: -30px;
    left: 0; }
  .search-api-ranges-widget .yui3-g .range-box-right {
    position: absolute;
    top: -30px;
    right: 0; }
    .search-api-ranges-widget .yui3-g .range-box-right input {
      text-align: right; }

.search-api-ranges-widget .form-submit {
  margin-top: 1em; }
  .js .search-api-ranges-widget .form-submit {
    display: none; }

.node-type-project #content {
  position: relative; }

.node-type-project .stoerer {
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  transform: rotate(5deg);
  background-color: #9D1309;
  color: #fff;
  font-weight: bold;
  padding: 1em;
  position: absolute;
  right: 2em;
  top: -1em;
  z-index: 200; }

.node-type-project .field-collection-item-field-objects {
  border-bottom: 1px solid #cccccc;
  padding: 0.5em 0; }
  .node-type-project .field-collection-item-field-objects:first-child {
    padding-top: 0; }
  .node-type-project .field-collection-item-field-objects > .field-name-field-title {
    cursor: pointer;
    color: #333333;
    margin: 0; }
    .node-type-project .field-collection-item-field-objects > .field-name-field-title:before {
      font-family: 'FontAwesome';
      content: '\f105';
      padding-right: 0.4em;
      width: 0.6em;
      display: inline-block; }
    .node-type-project .field-collection-item-field-objects > .field-name-field-title.open:before {
      content: '\f107'; }

.node-type-project .field-name-field-geofield h2:first-child {
  margin-top: 1.5em; }

.node-type-project .field-name-field-floor-plans {
  display: none; }
  .node-type-project .field-name-field-floor-plans .field-name-field-title {
    margin: 0.5em 0; }
  .node-type-project .field-name-field-floor-plans .entity-field-collection-item {
    cursor: pointer;
    float: left;
    padding: 0.5em;
    width: 33%; }

.node-type-project .field-name-field-photos {
  overflow: hidden;
  *zoom: 1;
  padding: 2em 0 0; }
  .node-type-project .field-name-field-photos .field-items {
    overflow: hidden;
    *zoom: 1;
    margin: 1em 0; }
    .node-type-project .field-name-field-photos .field-items .field-item {
      margin-bottom: 1em; }
      .node-type-project .field-name-field-photos .field-items .field-item:nth-child(n) {
        width: 22.85714%;
        clear: right;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%;
        display: block; }
      .node-type-project .field-name-field-photos .field-items .field-item:nth-child(4n+0) {
        width: 22.85714%;
        clear: right;
        float: right;
        margin-right: 0; }
      .node-type-project .field-name-field-photos .field-items .field-item:nth-child(4n+1) {
        width: 22.85714%;
        clear: right;
        float: left;
        margin-left: 0;
        margin-right: 2.85714%;
        clear: both; }

.node-type-project .view-header-projekt .views-row {
  margin: 0;
  overflow: hidden;
  position: relative; }
  .node-type-project .view-header-projekt .views-row .views-field-title {
    position: absolute;
    bottom: 33%;
    left: 0;
    z-index: 200;
    padding: 0.5em 2em;
    background-color: rgba(255, 255, 255, 0.75); }
    @media (min-width: 670px) {
      .node-type-project .view-header-projekt .views-row .views-field-title {
        padding: 1em 2em; } }
    .node-type-project .view-header-projekt .views-row .views-field-title h1 {
      font-size: 1.25em;
      color: #3a4496;
      text-transform: uppercase; }
      @media (min-width: 670px) {
        .node-type-project .view-header-projekt .views-row .views-field-title h1 {
          font-size: 2.5em; } }
      .node-type-project .view-header-projekt .views-row .views-field-title h1:before {
        display: none;
        content: ''; }
  .node-type-project .view-header-projekt .views-row .views-field-field-image ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .node-type-project .view-header-projekt .views-row .views-field-field-image li {
    list-style: none;
    display: none; }
    .node-type-project .view-header-projekt .views-row .views-field-field-image li:first-child {
      display: block; }

.node-type-project .block-webform {
  margin: 2em 0; }
  @media (min-width: 670px) {
    .node-type-project .block-webform .webform-component--left {
      position: relative;
      width: 48.57143%;
      float: left;
      margin-right: -100%;
      margin-left: 0;
      clear: none; }
    .node-type-project .block-webform .webform-component--right {
      position: relative;
      width: 48.57143%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none; }
    .node-type-project .block-webform select {
      width: 100%; }
    .node-type-project .block-webform .form-type-checkbox {
      margin-right: 1em; }
    .node-type-project .block-webform .form-actions {
      overflow: hidden;
      *zoom: 1;
      clear: both;
      text-align: center; } }

#block-block-4 {
  overflow: hidden;
  *zoom: 1; }
  #block-block-4 .col {
    width: 30.43478%;
    clear: right;
    float: left;
    margin-left: 0;
    margin-right: 4.34783%; }
    #block-block-4 .col:last-child {
      margin: 0; }

#block-block-5 {
  overflow: hidden;
  *zoom: 1;
  margin: 0;
  padding: 0; }
  @media (min-width: 670px) {
    #block-block-5 {
      margin: 0; } }
  #block-block-5 p {
    margin: 0; }
    #block-block-5 p a {
      display: block;
      padding: 1em 2em;
      background-color: #3a4496;
      color: #fff;
      text-decoration: none; }
      @media (min-width: 670px) {
        #block-block-5 p a {
          padding: 2em; } }
      #block-block-5 p a:hover {
        background-color: #2c3371; }

#block-block-2 + #block-block-5 {
  margin-top: -1em; }
  @media (min-width: 670px) {
    #block-block-2 + #block-block-5 {
      margin-top: -2em; } }

#block-block-6 {
  overflow: hidden;
  *zoom: 1;
  background-color: #9D1309;
  color: #fff;
  margin-top: -2em;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em; }
  .field-name-body #block-block-6 {
    margin-top: 0; }
  #block-block-6 h2 {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0;
    text-transform: uppercase;
    color: #fff; }
    #block-block-6 h2 span {
      font-size: 0.85em;
      font-weight: normal;
      text-transform: none; }
  #block-block-6 h3 {
    color: #fff; }
  #block-block-6 a {
    color: #fff;
    text-decoration: none; }
  #block-block-6 p:last-child {
    margin-bottom: 0; }

#block-bean-info-block {
  background-color: #3a4496;
  color: #fff;
  padding: 1em;
  margin: 0;
  cursor: pointer; }
  #block-bean-info-block .contextual-links-wrapper {
    display: none !important; }
  @media (min-width: 670px) {
    #block-bean-info-block {
      padding: 2em; } }
  #block-bean-info-block h2 {
    color: #fff;
    font-weight: bold;
    font-size: 1em;
    margin: 0; }
    #block-bean-info-block h2::before {
      display: none; }
  #block-bean-info-block .block__title {
    display: none; }
  #block-bean-info-block .field-name-field-popup-text {
    display: none; }

.mfp-wrap .mfp-close {
  color: #3a4496;
  font-family: inherit;
  background-image: url("../images/icon-close.svg");
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  top: 1rem;
  right: 1rem; }
  .mfp-wrap .mfp-close:active {
    top: 1rem; }

.mfp-wrap .field-name-field-popup-text {
  color: #3a4496;
  position: relative;
  margin: 0 auto;
  max-width: calc(1280px - 4rem);
  background-color: #fff;
  padding: 2em; }
  .mfp-wrap .field-name-field-popup-text h2 {
    font-weight: bold; }

#block-block-2 + #block-bean-info-block {
  margin-top: -1em; }
  @media (min-width: 670px) {
    #block-block-2 + #block-bean-info-block {
      margin-top: -2em; } }

#block-locale-language {
  padding-right: 0;
  position: absolute;
  top: 2em;
  right: 1em; }
  @media (min-width: 770px) {
    #block-locale-language {
      right: 2em; } }
  #block-locale-language > ul {
    display: block;
    text-align: right;
    margin: 0;
    line-height: 1; }
    @media (min-width: 770px) {
      #block-locale-language > ul {
        padding: 0; } }
    #block-locale-language > ul li {
      list-style: none;
      display: inline-block;
      text-transform: uppercase;
      color: #bfbfbf; }
      #block-locale-language > ul li.first:after {
        content: '·';
        display: inline-block;
        padding: 0 0em 0 0.3em; }
      #block-locale-language > ul li a {
        text-decoration: none;
        text-transform: uppercase;
        color: #bfbfbf; }
      #block-locale-language > ul li.active a, #block-locale-language > ul li:hover a {
        text-decoration: underline; }

@media (min-width: 670px) {
  .view-mode-teaser.has-left .ds-left {
    width: 31.42857%;
    float: left;
    margin-right: -100%;
    margin-left: 0;
    clear: none;
    overflow: hidden;
    *zoom: 1; }
  .view-mode-teaser.has-left .ds-right {
    width: 65.71429%;
    float: right;
    margin-left: 0;
    margin-right: 0;
    clear: none;
    overflow: hidden;
    *zoom: 1; }
    .view-mode-teaser.has-left .ds-right h2 a {
      text-decoration: none; }
    .view-mode-teaser.has-left .ds-right .label-inline {
      display: inline-block;
      min-width: 8em; }
    .view-mode-teaser.has-left .ds-right .field-name-node-link {
      margin-top: 1em; } }

.view-mode-full .group-left {
  width: 100%;
  float: right;
  margin-left: 0;
  margin-right: 0;
  clear: none; }
  @media (min-width: 670px) {
    .view-mode-full .group-left {
      width: 48.57143%;
      float: left;
      margin-right: -100%;
      margin-left: 0;
      clear: none; } }

.view-mode-full .group-right {
  overflow: hidden;
  *zoom: 1;
  margin: 2em 0;
  width: 100%;
  float: right;
  margin-left: 0;
  margin-right: 0;
  clear: none; }
  .view-mode-full .group-right .field-group-div {
    margin-bottom: 0; }
  @media (min-width: 670px) {
    .view-mode-full .group-right {
      margin: 0;
      width: 48.57143%;
      float: right;
      margin-left: 0;
      margin-right: 0;
      clear: none; } }

.view-mode-full h2 {
  font-size: 1em;
  font-weight: bold;
  margin: 1.5em 0 1em;
  color: #3a4496; }
  .view-mode-full h2:first-child {
    margin-top: 0; }
  @media (min-width: 770px) {
    .view-mode-full h2 {
      font-size: 1em; } }

.view-mode-full .group-left {
  overflow: hidden;
  *zoom: 1; }
  .view-mode-full .group-left .field-group-fieldset,
  .view-mode-full .group-left .field-group-div {
    margin: 1em 0;
    padding-top: 1em;
    padding-left: 0;
    padding-right: 0;
    border: 0px;
    border-top: 1px solid #cccccc; }
    .view-mode-full .group-left .field-group-fieldset legend,
    .view-mode-full .group-left .field-group-div legend {
      margin-left: 0.5em; }

.view-mode-full .label-inline {
  display: inline-block;
  min-width: 12em; }

.view-mode-full .field-name-anfrage-button {
  margin: 1em 0; }
  .view-mode-full .field-name-anfrage-button a {
    display: inline-block;
    color: #fff;
    background-color: #3a4496;
    padding: 0.5em 1em;
    text-decoration: none; }
    .view-mode-full .field-name-anfrage-button a:hover {
      background-color: #2c3371; }

.view-mode-full .field-name-field-vorname,
.view-mode-full .field-name-field-name,
.view-mode-full .field-name-field-plz-kontakt,
.view-mode-full .field-name-field-ort-kontakt {
  display: inline-block; }

.view-mode-full .field-name-field-vorname,
.view-mode-full .field-name-field-plz-kontakt {
  margin-right: 0.2em; }

.view-mode-full .field-name-field-tel-zentrale .label-inline,
.view-mode-full .field-name-field-email-zentrale .label-inline {
  min-width: 0; }

.view-mode-full .field-name-field-tel-zentrale {
  margin-top: 1em; }

.view-mode-full .field-name-field-download {
  margin: 1em 0; }

.view-mode-full .country {
  display: none; }

.view-mode-full .field-name-field-nutzungsart span {
  display: inline-block; }
  .view-mode-full .field-name-field-nutzungsart span:before {
    content: ',';
    padding-right: 0.2em; }
  .view-mode-full .field-name-field-nutzungsart span:nth-child(2):before {
    content: '';
    padding: 0; }

.view-header .views-row {
  line-height: 0; }
  .view-header .views-row ul {
    margin: 0; }

/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */
html {
  height: 100%;
  width: 100%;
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

div > *:first-child {
  margin-top: 0; }

div > *:last-child {
  margin-bottom: 0; }

img {
  max-width: 100%;
  height: auto; }

body {
  font-family: "Arial";
  line-height: 1.5;
  font-size: 1.4em;
  color: #333333; }

h1 {
  font-weight: 400;
  font-size: 1.25em;
  margin: 0em 0 1em;
  color: #3a4496;
  font-weight: bold;
  position: relative; }
  h1:before {
    display: inline-block;
    content: '';
    color: #3a4496;
    font-size: 1.5em;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    margin-bottom: 4px;
    margin-right: 10px;
    background-color: #3a4496; }
  @media (min-width: 770px) {
    h1 {
      font-size: 1.25em; } }

h2 {
  font-size: 1.25em;
  font-weight: 400;
  margin: 1.5em 0 1em;
  color: #3a4496;
  font-weight: normal; }
  h2.block-title {
    position: relative;
    font-weight: normal;
    font-size: 1em; }
    h2.block-title:before {
      display: inline-block;
      content: '';
      color: #3a4496;
      font-size: 1.5em;
      vertical-align: middle;
      width: 11px;
      height: 11px;
      margin-bottom: 3px;
      margin-right: 6px;
      background-color: #3a4496; }
    #footer h2.block-title {
      font-size: 1em;
      font-weight: bold;
      color: #fff; }
  .node-immobilie.node-teaser h2 {
    font-size: 1em;
    font-weight: bold; }
    .node-immobilie.node-teaser h2 a {
      color: #3a4496; }
  @media (min-width: 770px) {
    h2 {
      font-size: 1.25em; } }

h3 {
  font-size: 1em;
  font-weight: 600;
  margin: 1em 0 1em;
  color: #3a4496; }
  #footer h3 {
    color: #fff; }

h4, h5, h6 {
  font-size: 1em;
  font-weight: 600;
  margin: 1em 0 1em; }
  #footer h4, #footer h5, #footer h6 {
    color: #fff; }

p {
  font-weight: 400;
  margin: 1em 0; }
  #footer p {
    color: #fff;
    margin: 0;
    line-height: 1; }

strong {
  font-weight: 600; }

a {
  text-decoration: underline;
  color: #333333; }
  a.more-link {
    display: inline-block;
    padding-left: 0.3em;
    text-decoration: none; }
    a.more-link:before {
      font-family: 'FontAwesome';
      display: inline-block;
      content: '\f0da';
      padding-right: 0.5em; }
    .view-mode-one_pager a.more-link {
      margin-top: 1em;
      background-color: #3a4496;
      color: #fff;
      padding: 0.25em 0.75em;
      border-radius: 2px;
      text-decoration: none; }
      .view-mode-one_pager a.more-link:hover {
        background-color: #2c3371; }
  a:hover {
    text-decoration: underline;
    color: #3a4496; }
  a.anker {
    display: block;
    clear: both;
    overflow: hidden;
    *zoom: 1; }
  #footer a {
    color: #fff; }

ul, ol {
  margin: 1em 0;
  padding-left: 1.3em; }
  ul li ul, ol li ul {
    margin: 0; }
  .block ul, .block ol {
    padding: 0;
    margin: 0; }
    .block ul li, .block ol li {
      list-style: none; }
      .block ul li a, .block ol li a {
        text-decoration: none; }

ol {
  list-style: decimal; }

ul {
  list-style: square; }
  ul.pager {
    width: 75%;
    margin: 2em auto;
    text-align: center; }
    ul.pager li {
      display: inline;
      list-style: none;
      padding: 0 0.5em; }

.element-invisible {
  width: 1px;
  opacity: 0; }

.description {
  font-style: italic; }

em, i {
  font-style: italic; }

sup {
  vertical-align: super;
  font-size: smaller; }

sub {
  vertical-align: sub;
  font-size: smaller; }

q:lang(de) {
  quotes: "\201E" "\201C" "\201A" "\2018"; }

q:lang(de-DE) {
  quotes: "\00BB" "\00AB" "\203A" "\2039"; }

q:lang(de-CH) {
  quotes: "\00AB" "\00BB" "\2039" "\203A"; }

q:before {
  content: open-quote;
  color: #333333; }

q:after {
  content: close-quote;
  color: #333333; }

blockquote {
  padding-left: 2em; }

mark {
  background: #A8BFE6; }

code {
  font-family: monospace; }

table caption {
  font-weight: bold;
  padding: 0.5em 0; }

table tr td, table tr th {
  padding: 0.5em; }
  table tr td:first-child, table tr th:first-child {
    padding-left: 0; }

.label-inline {
  display: inline-block;
  min-width: 8em; }

.more-link {
  display: inline-block; }
  .more-link a:after {
    content: ' »'; }

.field-name-field-e-mail {
  margin-top: 1em; }

.views-row {
  margin: 2em 0; }
  .views-row.views-row-last {
    margin-bottom: 0; }

#page {
  overflow: hidden; }

.file img {
  display: none; }

.file a[type*='pdf']:before {
  font-family: 'FontAwesome';
  content: '\f1c1';
  display: inline-block;
  padding-right: 0.3em; }

.messages {
  padding: 2em;
  color: #fff; }
  .messages.error {
    background-color: #9D1309; }
  .messages.warning {
    background-color: #B8860B; }
  .messages.status {
    background-color: #006400; }
  .messages a {
    color: #fff; }

#main img {
  max-width: 100%;
  height: auto; }

#main .downloads {
  margin: 1em 0; }
  #main .downloads .file {
    display: block; }
    #main .downloads .file img {
      position: relative;
      top: 0.2em; }
    #main .downloads .file a {
      text-decoration: none; }

#main .view-aktuelles h3 {
  margin: 0; }
  #main .view-aktuelles h3 a {
    font-weight: 600;
    text-decoration: none; }

#main .view-aktuelles a.more-link {
  display: inline-block;
  margin-left: 0.3em;
  text-decoration: none; }
  #main .view-aktuelles a.more-link:hover {
    text-decoration: underline; }

#main .view-aktuelles p {
  margin: 0; }

#main .view-aktuelles .views-row {
  margin: 1.5em 0; }

#main aside.col-2 {
  border-top-width: 1px;
  border-style: solid; }
  #main aside.col-2 h2 {
    font-size: 2.4em; }

#footer h2 {
  margin-bottom: 0.5em; }

#footer .block:first-child {
  padding-top: 0; }

#footer .block:last-child {
  padding-bottom: 0; }

#footer #block-block-3 a {
  float: left;
  width: 25%;
  text-decoration: none; }
  #footer #block-block-3 a img {
    width: auto; }

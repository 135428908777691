// block: Neu und exklusiv! / Neubauwohnungen Pankow southside

#block-block-5{
	@include clearfix;
	margin: 0;
	padding: 0;

	@include breakpoint($breakpoint_mobile){
		margin: 0;
	}


	p {
		margin: 0;

		a{
			display: block;
			padding: 1em 2em;
			background-color: $color-primary;
			color:#fff;
			text-decoration: none;

			@include breakpoint($breakpoint_mobile){
				padding: 2em;
			}

			&:hover {
				background-color: $color-primary-shade-1;
			}
		}
	}
}

#block-block-2 + #block-block-5 {
  margin-top: -1em;

  @include breakpoint($breakpoint_mobile) {
    margin-top: -2em;
  }
}

*{
	@include box-sizing('border-box');
}

body{
	background-color:$background-body;
	clear:both;
	@include background-image(linear-gradient(to bottom right,shade($color-primary,50%),shade($color-primary,5%)));
	background-attachment: fixed;
}


#top{
	position:relative;
}


#page{
	padding:0;
	margin:0 auto;
	max-width:$page-width;
}

#header{
	background-color: $background-header;
}

#logo{
	padding:$page-gutter $page-gutter/2;
	background:$background-header;
	line-height:0;

	img{
		width:$logo-width;

	}
}
.nav-fixed{
	#nav-main-outer{
		position:fixed;
		top:0px;
		left:0px;
		width:100%;
		z-index:350;

			#nav-main-inner{
				padding:0;
				max-width:$page-width;
				margin:0 auto;

				#nav-main{

				}
			}
	}
}


#header{
	@include clearfix;
	position:relative;




}

#main{
	@include clearfix;
	background-color:$background-main;

	padding:0;


	.main-inner{
		max-width:$page-width;
		padding:$page-gutter 0 0;
		margin:0 auto;
		@include clearfix;


	}

	#content{
		padding:$page-gutter/2;
	}

	#content + aside{
		margin-top:$page-gutter;
	}

}

aside{
	padding:$page-gutter/2 0;

	.block{
		margin:$page-gutter/2 0;
		padding:0 $page-gutter/2;

		&.first{
			margin-top:0;
		}
	}

	&#right{
		background-color:$color-primary-tint-3;
	}

	#block-block-2,
	#block-block-7 {
		background-color:$color-primary-tint-2;
		margin-top:0-$page-gutter/2;
		padding:$page-gutter/2;

		h2{
			@include headline-3;
		}

	}
}

#footer{
	padding:1em $page-gutter/2;
	background-color:$background-footer;

}

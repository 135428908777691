
.view-mode-full{

  @include layout(12,1/2){
    .group-left{
      @include grid-span(12,1);

      @include breakpoint($breakpoint_mobile){
        @include grid-span(6,1);
      }
    }


    .group-right{
      @include clearfix;
      margin: 2em 0;


      .field-group-div{
          margin-bottom:0;
      }

      @include grid-span(12,1);

      @include breakpoint($breakpoint_mobile){
        margin: 0;
        @include grid-span(6,7);
      }
    }

    h2{
      @include headline-3;
    }
    .group-left{
      @include clearfix;
      .field-group-fieldset,
      .field-group-div{
        margin:1em 0;
        padding-top:1em;
        padding-left:0;
        padding-right:0;

        border:0px;
        border-top:1px solid $color_grey;

        legend{
          margin-left:0.5em;
        }
      }
    }
  }

  .label-inline{
    display:inline-block;
    min-width:12em;
  }


  .field-name-anfrage-button{
    margin:1em 0;
    a{
      display:inline-block;
      color:#fff;
      background-color:$color-primary;
      padding:0.5em 1em;
      text-decoration:none;

      &:hover{
        background-color: $color-primary-shade-1;
      }

    }
  }

  .field-name-field-vorname,
  .field-name-field-name,
  .field-name-field-plz-kontakt,
  .field-name-field-ort-kontakt{
    display:inline-block;
  }

  .field-name-field-vorname,
  .field-name-field-plz-kontakt{
    margin-right:0.2em;
  }

  .field-name-field-tel-zentrale,
  .field-name-field-email-zentrale{
    .label-inline{
      min-width:0;
    }
  }

  .field-name-field-tel-zentrale{
    margin-top:1em;
  }

  .field-name-field-download{
    margin:1em 0;
  }

  .country{
    display:none;
  }

  .field-name-field-nutzungsart{

    span{
      display:inline-block;
      &:before{
        content:',';
        padding-right:0.2em;


      }

      &:nth-child(2){

        &:before{
          content:'';
          padding:0;

        }
      }
    }
  }



}

@include breakpoint($breakpoint_mobile){



}

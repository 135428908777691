#block-current-search-standard{
  .current-search-item-text{
    display:none;
  }

  margin-bottom:0;

  & + #block-block-3{
    margin-top:0;
  }

}

#block-block-3{
  margin-top:0;
}

.block-facetapi,
.block-current-search{

  ul{
    padding:0;

    li{
      list-style:none;

      a{
        text-decoration:none;
      }

      // span.reset{
      //   display:inline-block;
      //   background:image-url('hwub-close.svg') left center no-repeat;
      //   background-size:0.7em;
      //   width:1em;
      //   height:0.7em;
      //   text-decoration:none;
      //
      // }

      span.reset{
        &:after{
          //font-family:'FontAwesome';
          display:inline;
          content:'\2A2F';
          font-size: 2em;
          line-height:0;
        }
      }

      .field_kaltmiete,
      .field_kaufpreis{
        &:after{
/*           display:inline-block;
content:'€';
margin-left:0.3em; */
        }
      }

      .field_wohnflaeche{
        &:after{
/*           display:inline-block;
content:'m²';
margin-left:0.3em; */
        }
      }


      .element-invisible{
        display:none;
      }
    }
  }

  #search-api-ranges-field_wohnflaeche{
    .form-item-range-to{
      &:after{
        display:inline-block;
        content:'m²';
      }
    }
  }

  #search-api-ranges-field_kaltmiete,
  #search-api-ranges-field_kaufpreis{
    .form-item-range-to{
      &:after{
        display:inline-block;
        content:'€';
      }
    }
  }
}

// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  @if $legacy-support-for-ie6 or $legacy-support-for-ie7 {
    clip: rect(1px 1px 1px 1px); // IE6 and IE7 use the wrong syntax.
  }
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin grids($columns) {
  @include layout($columns, 1/8) {
    &:nth-child(n) {
      @include float-span(1);
      display:block;
    }

    &:nth-child(#{$columns}n+0) {
      @include float-span(1, 'last');
    }

    &:nth-child(#{$columns}n+1) {
      @include float-span(1, 'first');
    }
  }
}

@mixin headline-1{
  font-weight:400;
  font-size:1.25em;
  margin:0em 0 1em;
  color:$font-h1-color;
  font-weight:bold;
  position:relative;

  &:before{
    display: inline-block;
    content:'';
    color:$color-primary;
    font-size:1.5em;
    vertical-align:middle;
    width:15px;
    height:15px;
    margin-bottom:4px;
    margin-right:10px;
    background-color:$color-primary;

  }

  @include breakpoint($breakpoint_medium){
    font-size:1.25em;
  }
}

@mixin headline-2{
  font-size:1.25em;
  font-weight:400;
  margin:1.5em 0 1em;
  color:$font-h2-color;
  font-weight:normal;

  &.block-title{

    position:relative;
    font-weight:normal;
    font-size:1em;

    &:before{
      display: inline-block;
      content:'';
      color:$color-primary;
      font-size:1.5em;
      vertical-align:middle;
      width:11px;
      height:11px;
      margin-bottom:3px;
      margin-right:6px;
      background-color:$color-primary;
    }

    #footer &{
      font-size:1em;
      font-weight:bold;
      color:$footer-font-color;
    }
  }

  .node-immobilie.node-teaser &{
    font-size:1em;
    font-weight:bold;


    a{
      color:$color-primary;
    }
  }

  @include breakpoint($breakpoint_medium){
    font-size:1.25em;
  }
}

@mixin headline-3{
  font-size:1em;
  font-weight:bold;
  margin:1.5em 0 1em;
  color:$font-h3-color;

  &:first-child{
    margin-top:0;
  }

  @include breakpoint($breakpoint_medium){
    font-size:1em;
  }
}

@mixin headline-4{
  font-size:1em;
  font-weight:normal;
  margin:1.5em 0 1em;
  color:$font-h3-color;

  &:first-child{
    margin-top:0;
  }

  @include breakpoint($breakpoint_medium){
    font-size:1em;
  }
}

.node-type-project {

  #content {
    position: relative;
  }

  .stoerer {
    @include transform(rotate(5deg));
    background-color: $color_error;
    color: #fff;
    font-weight: bold;
    padding: 1em;
    position: absolute;
    right: 2em;
    top: -1em;
    z-index: 200;
  }

  .field-collection-item-field-objects {
    border-bottom: 1px solid $color-grey;
    padding: 0.5em 0;

    &:first-child {
      padding-top: 0;
    }

    > .field-name-field-title {
      cursor: pointer;
      color: $font-color;
      margin: 0;

      &:before {
        font-family: 'FontAwesome';
        content: '\f105';
        padding-right: 0.4em;
        width: 0.6em;
        display: inline-block;
      }

      &.open:before {
        content: '\f107';
      }
    }
  }

  .field-name-field-geofield {
    h2:first-child {
      margin-top: 1.5em;
    }
  }

  .field-name-field-floor-plans {
    display: none;

    .field-name-field-title {
      margin: 0.5em 0;
    }

    .entity-field-collection-item {
      cursor: pointer;
      float: left;
      padding: 0.5em;
      width: 33%;
    }
  }

  .field-name-field-grundriss {


  }

  .field-name-field-photos {
    @include clearfix;
    padding: 2em 0 0;

    .field-items{
      @include clearfix;
      margin: 1em 0;

      .field-item {
        @include grids(4);
        margin-bottom: 1em;
      }
    }
  }

  .view-header-projekt {
    .views-row{
      margin: 0;
      overflow: hidden;
      position: relative;

      .views-field-title {
        position: absolute;
        bottom: 33%;
        left:0;
        z-index: 200;
        padding: $page-gutter/4 $page-gutter;
        background-color: rgba(255, 255, 255, 0.75);
        //background-color: rgba(58, 68, 150, 0.5);

        @include breakpoint($breakpoint_mobile) {
          padding: $page-gutter/2 $page-gutter;
        }

        h1{
          font-size: 1.25em;

          @include breakpoint($breakpoint_mobile) {
            font-size: 2.5em;
          }
          color: rgb(58, 68, 150);
          text-transform: uppercase;

          &:before {
            display: none;
            content: '';
          }
        }
      }

      .views-field-field-image{
        ul{
          margin: 0;
          padding: 0;
          list-style: none;
        }

        li{
          list-style: none;
          display: none;
          &:first-child {
            display: block;
          }
        }
      }
    }
  }

  .block-webform{
    margin: 2em 0;

    @include breakpoint($breakpoint_mobile){

      @include layout(12,1/2){
        .webform-component--left{
          position: relative;
          @include grid-span(6,1);
        }

        .webform-component--right{
          position: relative;
          @include grid-span(6,7);
        }
      }

      select {
        width: 100%;
      }

      .form-type-checkbox {
        margin-right: 1em;
      }


      .form-actions{
        @include clearfix;
        clear: both;
        text-align: center;
      }
    }
  }
}

.node-type-immobilie .geofieldMap{
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  overflow: hidden;

}

.leaflet-popup-content-wrapper{
  border-radius:0;
  .leaflet-popup-content{
    margin:0;
    padding:1em;
  }
}

.leaflet-popup-tip-container{
  top:-10px;
}

.leaflet-popup-tip{
  box-shadow:none;

}

.leaflet-popup-close-button{
  text-indent:-9999px;
  &:after{
    display: inline-block;
    content: '\2A2F';
    font-size: 1.75em;
    line-height: 0;
    text-indent:9980px;
  }
}



.leaflet-tile-container{
  @include filter(grayscale(100%));
}

.node-type-immobilie .field-name-field-geofield-kurz,
.node-type-immobilie .field-name-field-geofield{
  position: relative;
  padding-bottom: 56%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
}

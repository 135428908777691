form{

	margin:2em 0;

	input[type=text],
	input[type=email],
	input[type=password],
	input[type=number],
	.form-textarea-wrapper textarea{
		border-width:1px;
		border-style:solid;
		font-size:1em;
		width:100%;
		padding:0.3em 0.2em;
		@include box-sizing(border-box);
		font-family:$font-family;
		webkit-appearance: none;
		@include border-radius(0);
		border-color:$color-grey;
		background-color: white;


		&:focus{
			border-color: #000;
			outline: none;
		}

		&#edit-submitted-bitte-rufen-sie-mich-zuruck-am-uhrzeit{
			width:100px;
		}

		@include breakpoint($breakpoint_medium){
			max-width:30em;

		}

	}

	input[readonly=readonly]{
		border:0;
		padding-left:0;
		margin-left:0;


		&#edit-submitted-objekttitel{
			max-width:100%;
			width:100%;
		}
	}


	textarea{
		min-width:100%;
	}

	.form-item{
		margin:1em 0;

		.form-item{
			margin:0;
		}

	}

	.webform-component-markup{
		margin:1em 0 0.5em;
	}

	.form-item-autocomplete{
		input[type=text]{
			background-image:url('/misc/throbber.gif');
			background-position: 100% 8px;
			background-repeat:no-repeat;

			&.throbbing{
				background-position: 100% -12px;
			}
		}


	}

	input[type=text],
	input[type=email],
	label{
		line-height:1.5;



	}

	input[type=submit]{
		cursor: pointer;
		font-family:$font-family;
		font-size:1em;
		position: relative;
		text-decoration: none;
		text-align: center;
		display: inline-block;
		padding:0.5em 1em;
		margin:gutter-span()/2 gutter-span()/2 gutter-span()/2 0;
		line-height: $line-height;
		border:0;
		-webkit-appearance: none;
		background-color: $button-background;
		border-color: darken($button-background,20%);
		color: $button-font-color;
		@include transition(background-color);

		&:hover{
			background-color: darken($button-background,10%);
		}
	}

	label{
		display:block;
		width:100%;
		margin-bottom:0.5em;
	}

	#edit-submitted-objekttitel{
    font-weight: bold;

  }

	#webform-component-objektnummer{
		label{
			margin-right:0;
		}
	}




	#webform-component-suchkriterien-etage-objekt-mit-fahrstuhl,
	#webform-component-suchkriterien-anzahl-der-zimmer,
	#webform-component-suchkriterien-grosse-der-wohnung,
	#webform-component-suchkriterien-etage-objekt-ohne-fahrstuhl,
	#webform-component-suchkriterien-kaufpreis
	{
		*{
			display:inline-block;
			width:auto;
		}

		input[type="number"]{
			max-width:6em;
		}

		.webform-component-markup{
			display:block;
			width:100%;
			margin:0;
			margin-bottom:0.5em;

			p{
				margin:0;
			}
		}
	}


	.form-type-radio,
	.form-type-checkboxes,
	.form-type-checkbox{
		display:inline-block;

		label,
		.form-radio,
		.form-checkbox{
			width:auto;
			display:inline-block;
		}


	}

	.password-strength{
		float:none;
		margin:1em 0;
	}

	.password-suggestions{

	}

	fieldset{
		margin:2em 0;
		padding:0;

		border:0px;
		border-top:0;

		legend{
			@include headline-2;
			padding-left:0;
			margin:0;
			display:block;
			width:100%;
			font-size: 1.25em;
			line-height:1;
			padding-bottom: 1em;
			margin-bottom: 1em;
			border-bottom:1px solid $color_grey;



		}


		&.collapsible{
			legend{
				border:0;
				padding:1em 0;
				border-bottom:1px solid lighten(#000,25%);
				line-height:$line-height;
				display:block;
				width:100%;
			}
		}

		.fieldset-description{

			font-style:italic;
		}

		.fieldset-legend{
		}

		&#webform-component-suchkriterien-stadtteile{
			label,
			input{
				display:inline-block;
				width:auto;
			}

			.webform-component-markup{
				margin-bottom:0.5em;
			}

			label{
				width:5%;

				@include breakpoint($breakpoint_mobile){
					width:3%;
				}


			}

			input{
				width:80%;
			}


		}

		.form-item{
			&:last-child{
				margin-bottom:0;
			}
		}

		fieldset{
			margin:1em 0;
			.form-item{
				margin:0;
			}
		}

	}

	.resizable-textarea{
		min-width:100%;
		.grippie{
			display:none;
		}
	}

	#edit-actions{
		#edit-cancel{
			display:inline-block;
			background-color:$color_error;
			color:#fff;
			padding:0.5em 1em;
			text-decoration:none;

			@include single-transition(background-color 300ms ease-out);

			&:hover{
				background-color: darken($color_error,10%);
			}
		}

	}

	.vertical-tabs{
		fieldset{
			padding:1em;
		}
	}


	&#webform-client-form-15{
		#webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-select,
		#webform-component-bitte-rufen-sie-mich-zurueck-am--datum-egal,
		#webform-component-bitte-rufen-sie-mich-zurueck-am--datum,
		#webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit,
		#webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-egal{
			display:inline-block;
			width:60%;
			margin-bottom:0;
		}



		#webform-component-bitte-rufen-sie-mich-zurueck-am--datum-egal,
		#webform-component-bitte-rufen-sie-mich-zurueck-am--uhrzeit-egal{
			width:20%;
			margin-right:1em;
		}

		.webform-component-markup{
			@include headline-2;
			margin:0;
			margin-top:2em;
		}

	}
}

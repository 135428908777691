.search-api-ranges-widget{

  .text-range{
    display:none;
  }

  input.form-text{
    border:0;
    font-size:1em;
    @include box-shadow(none);
    background-color: transparent;
  }

  .yui3-g{
    position: relative;
    margin-top:3em;

    input{
      width:auto;
    }

    label{
      display:none;

    }

    .range-slider-box{
      display:block;
      width:100%;
      padding:0 0.6em;
    }

    .range-box-left{
      position: absolute;
      top:-30px;
      left:0;

    }

    .range-box-right{
      position: absolute;
      top:-30px;
      right:0;
      input{
        text-align:right;
      }

    }

  }

  .form-submit{
    margin-top:1em;
    .js &{
      display:none;
    }
  }

  #search-api-ranges-block-slider-view-form-field-kaufpreis{

  }
}

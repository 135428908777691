#block-menu-menu-footer-menu{
  ul{
    padding:0;
    margin:1em 0 0;
    text-align:left;
    line-height:1;

    @include breakpoint($breakpoint-medium){
      text-align:right;
      margin: 0;
    }

    li{
      display:inline-block;
      padding:0;
      list-style:none;
      padding:0;



      &:before{
        content:'·';
        padding:0 0.3em 0 0em;
        color:#fff;

      }


      a{
        text-decoration:none;
        &:hover{
          text-decoration:underline;
        }


      }

      &.first{
        &:before{
          display:none;

        }
      }

      &.last{
        padding-right:0;
      }

    }
  }

}

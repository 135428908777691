#block-block-6 {
	@include clearfix;
	background-color: $color_error;
	color: $color-white;
	margin-top: -2em;
	text-align: center;
	padding-top: 1em;
	padding-bottom: 1em;

	.field-name-body & {
		margin-top: 0;
	}

	h2 {
		font-size: 1.2em;
		font-weight: bold;
		margin-top: 0;
		text-transform: uppercase;
		color: $color-white;

		span{
			font-size: 0.85em;
			font-weight: normal;
			text-transform: none;
		}
	}

	h3 {
		color: $color-white;
	}

	a {
		color: $color-white;
		text-decoration: none;
	}

	p{
		&:last-child {
			margin-bottom: 0;
		}
	}

}

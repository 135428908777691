@include breakpoint($breakpoint_mobile){

	body{
		clear:none;
	}

	#top{
		position:relative;



	}

	#header{
		position:relative;
		padding:0;
		@include clearfix;


		ul{
			padding:0;
			margin:0;
		}


	}

	#main{

		h1.page__title{



			.one-sidebar.sidebar-first &{
				@include grid-span(8,5);
				@include clearfix;
			}


			.two-sidebars &{
				@include grid-span(4,5);
				@include clearfix;
			}

		}

		#content{
			@include clearfix;

			.no-sidebars &{
				@include grid-span(12,1);
				clear:left;
			}

			.one-sidebar.sidebar-first &{
				@include grid-span(8,5);

			}

			.one-sidebar.sidebar-second &{
				@include grid-span(8,1);
			}

			.two-sidebars &{
				@include grid-span(4,5);
			}

			& + aside{
				margin-top:0;
			}
		}

		aside{
			&#left{
				@include grid-span(4,1);
				clear:left;
			}

			&#right{
				@include grid-span(4,9);
			}
		}
	}


	#footer{
		@include clearfix;
		padding:1em $page-gutter;
		background-color:$background-footer;

		.block{
			@include float-span(6);
		}
	}
}



nav#nav-main{
	width:100%;
	height:3.1em;
	overflow:hidden;
	padding:0;

	ul.menu,
	ul.menu-link{
		position:relative;
		padding:0;
		margin:0;
		line-height:1;
		text-align:left;


		li{
			float:left;
			position:static;
			width:100%;
			line-height:1;
			border-bottom-width:1px;
			border-style:solid;
			list-style:none;
			background:$nav-main-mobile-background;
			border-color:$nav-main-mobile-background;


			&.expanded{
				& > a{
					position:relative;
				}

				& > a{



					&:after{
						content: "";
						display: block;
						width: 0;
						height: 0;
						border: inset 5px;
						border-color: transparent transparent transparent rgba(255, 255, 255, 0.4);
						border-left-style: solid;
						margin-right: 15px;
						margin-top: -4.5px;
						position: absolute;
						top: 50%;
						right: 0;
						z-index:0;
					}
				}

				ul{
					a{
						&:after{
							//content:none;
						}


					}
				}
			}



			&:hover{
				overflow:visible;
			}

			a{
				text-decoration:none;
				padding:1em $page-gutter;
				font-size:1.4rem;
				display:block;
				color:$nav-main-mobile-font-color;
				white-space: nowrap;

				//@include transition-property(color);
//				@include transition-duration(1s);

				&:hover,
				&.active{
					background-color:$nav-main-background-active;
					color:$nav-main-mobile-font-color;

				}

			}

			ul{
				position:absolute;
				z-index:99;
				left:100%;
				top:4.1rem;
				width:100%;

				li{
					float:none;
					padding:0;
					display:block;



					a{
						&:hover{
							//color:darken($color-primary,20%);
						}
					}

					ul{
						top:0;
					}
				}
			}
		}
	}

	ul.menu-link{

		display:block;

		li{

			border-bottom-width:0px;
			width:100%;

			a{
				position:relative;
				text-align:right;
				padding-right:2.5em;
				@include box-sizing(border-box);

				&:after{
					position:absolute;
					font-family:"FontAwesome";
					content: "\f0c9";
					right: 1rem;
					font-size:1.5em;
					//display: inline-block;
					//padding-left:3em;
					-webkit-font-smoothing: antialiased;
					font-smoothing: antialiased;
					top: 10px;
					//height: 0;

				}
			}
		}
	}
}


// +++++ Screen medium size +++++

@include breakpoint($breakpoint_medium){


	body:not(.menu-mobile){
		nav#nav-main{
			height:auto;
			overflow:visible !important;
			padding:0;
			background:$nav-main-background;


			ul.menu-link{
				display:none;
			}

			& div > ul.menu{
				text-align:left;
				@include display-flex;
				@include justify-content(space-between);

				li{
					@include flex-grow(1);
					text-align:center;
				}

				li.last a {
					padding-right:2em;
				}

				& > li:nth-child(1){

					a{
						padding:1em 2em;
						padding-left: $page-gutter;

					}

					li{


						a{
							padding:0.5em 1em;

						}

					}
				}

			}

			ul.menu{
				position:static;
				padding:0;
				text-align:right;

				li{
					position:relative;
					width:auto;
					border-width:0;
					background:none;
					float:none;
					display:inline-block;
					vertical-align:top;

					&.mobile{
						display:none;
					}

					&.last{
						& > a{
							padding-right:0;
						}
					}

					&.expanded{
						overflow:hidden;

						&:hover{
							//overflow:hidden;
							overflow:visible;
						}

						a{
							position:static;
						}
					}



					a{
						text-decoration:none;
						padding:1em 1.5em;
						line-height:1;
						font-size:1.4rem;
						display:block;
						color:$nav-main-medium-font-color;

						&.active,
						&:hover{
							background-color:$nav-main-background-active;
						}
					}

					&.expanded{
						a:after{
							content:none;
						}
					}

					ul{
						position:absolute;
						min-width:100%;
						z-index:300;
						width:auto;
						background:$color-primary;
						text-align:left;
						//padding:0.5em 0;
						left:auto;
						top:auto;



						li, li.last {
							float:none;
							padding:0;
							display:block;
							background-color:$color-primary;
							text-align:left;
							a{
								line-height:2;
								background-color: $color-primary;
								color: #FFF;
								padding: 0.5em 1em;
								&.active, &:hover{
									background-color:darken($color-primary,10%);
									color: #FFF;
								}
							}
							&.active a {
								background-color:darken($color-primary,10%);
								color: #FFF;
							}

							ul{
								right: 100%;
								width: 100%;
								display: block;
								position: absolute;
								top: 0;
								padding:0;
							}
						}

					}

					&.last{
						& > ul{
							right:0;
						}

					}
				}
			}
		}

	}

}




// +++++ Screen view +++++


@include breakpoint($breakpoint_medium){
	#header{

		&.fixed{
			position:fixed;
			z-index:200;
		}
	}


	nav#nav-main{
		position:relative;
		width:column-span(12,1);
		overflow:visible;
		background-color: $color-primary;
		height:auto;
		overflow:visible !important;
		padding:0;





		ul.menu-link{
			display:none;
		}

		& div > ul.menu{



			& > li:nth-child(1){



				li{
					a{
						padding:0.5em 1em;
					}

				}
			}

		}

		ul.menu{
			position:static;
			padding:0;
			text-align:right;


			li{
				position:relative;
				width:auto;
				border-width:0;
				background:none;
				float:none;
				vertical-align:top;

				&.mobile{
					display:none;
				}

				&.last{
					& > a{
						padding-right:0;
					}
				}

				&.expanded{
					overflow:hidden;

					&:hover{
						//overflow:hidden;
						overflow:visible;
					}
				}



				a{
					text-decoration:none;
					padding:1em 1.5em;
					line-height:1;
					font-size:1.4rem;
					display:block;
					color:$nav-main-font-color;


					&.active,
					&:hover{
						color:$nav-main-color-active;
					}



				}

				&.expanded{
					a:after{
						content:none;
					}
				}

				ul{
					position:absolute;
					z-index:300;
					width:auto;
					background:$color-primary;
					text-align:left;
					//padding:0.5em 0;
					left:auto;
					top:auto;



					li, li.last {
						float:none;
						padding:0;
						display:block;
						background-color:$color-primary;
						a{
							line-height:2;
							background-color: $color-primary;
							color: #FFF;
							padding: 0.5em 1em;
						  &.active, &:hover{
								background-color:darken($color-primary,10%);
								color: #FFF;
							}
						}
						&.active a {
							background-color:darken($color-primary,10%);
							color: #FFF;
						}

						ul{
							right: 100%;
							width: 100%;
							display: block;
							position: absolute;
							top: 0;
							padding:0;
						}
					}

				}

				&.last{
					& > ul{
						right:0;
					}

				}
			}
		}

		.medium-view &{
			position:static;
			@include grid-span(12,1);
			padding:0;
			background:$nav-main-background;

			& div > ul.menu{
				text-align:left;
				@include display-flex;
				@include justify-content(space-between);

				li.last a {
					padding-right:2em;
				}

				li:nth-child(1){

					a{
						padding:1em 2em;
					}
				}

			}

			ul.menu{

				li{
					a{

						color:$nav-main-medium-font-color;

						&.active,
						&:hover{
							color:#fff;
							background-color:$nav-main-background-active;
						}

					}
				}
			}
		}
	}
}

$font-family:'Arial';

$font-size:1.4em;
$line-height:1.5;

$color-primary:rgb(58,68,150);
$color-primary-shade-1:shade($color-primary,25%);
$color-primary-shade-2:shade($color-primary,50%);
$color-primary-tint-1:tint($color-primary,50%);
$color-primary-tint-2:tint($color-primary,90%);
$color-primary-tint-3:tint($color-primary,95%);

$color-secondary:#A8BFE6;
$color-white: #fff;

$color_status:#006400;
$color_error:#9D1309;
$color_warning:#B8860B;
$color_grey:lighten(#000,80%);


$font-color:lighten(#000,20%);
$font-h1-color:$color-primary;
$font-h2-color:$color-primary;
$font-h3-color:$color-primary;
$font-h4-color:$color-primary;

$background-body:shade($color-primary,50%);
$background-header:#fff;
$background-main:#fff;
$background-footer:$color-primary;


$nav-main-background:$color-primary;
$nav-main-background-active:$color-primary-shade-1;
$nav-main-mobile-background:$color-primary;

$nav-main-color-active:#fff;
$nav-main-font-color:#fff;
$nav-main-medium-font-color:#fff;
$nav-main-mobile-font-color:#fff;

$button-background:$color-primary;
$button-font-color:#fff;


$sidebar-block-background:#fff;

$gallery-background:#fff;

$footer-font-color:#fff;


$gutter:1/2;

@include add-grid(12);
@include add-gutter(0);


$half-gutter:gutter-span()/2;
$full-gutter:gutter-span();
$page-gutter:2em;

$logo-width:150px;
$page-width:1280px;
$breakpoint_mobile:670px;
$breakpoint_medium:770px;

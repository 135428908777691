.view-header{
  .views-row{
    overflow: hidden;

    li{
      display:none;

      &:nth-child(1){
        display:block;
      }
    }
  }
}


.slideshow{
  //border:1px solid #ebecf4;
  background-color:#ebecf4;

  img{
    padding:1em;
  }
  .group-right &{
    padding:0 !important;
  }


  line-height:0;

  .slideshow-next,
  .slideshow-prev {
    position: absolute;
    top:0;
    height:100%;
    width:20%;
    cursor:pointer;
    z-index:200;

    img{
      display:none;
      &:nth-child(1){
        display:block;
      }
    }

  }


  &:hover{
    .slideshow-next{
      right:0;
      background:image-url('hwub-button-arrow-right.png') right 2.5rem center no-repeat;
      @include opacity(0.6);
      background-size:27px;

      @include breakpoint($breakpoint_mobile){
        background-size:40px;
      }
    }

    .slideshow-prev{
      left:0;
      background:image-url('hwub-button-arrow-left.png') left 2.5rem center no-repeat;
      background-size:27px;
      @include opacity(0.6);

      @include breakpoint($breakpoint_mobile){
        background-size:40px;
      }


    }
  }

  .slideshow-prev,
  .slideshow-next{



    &:hover{
      @include opacity(1);

    }
  }
}

.slideshow-caption {
  background-color: #ebecf4;
  padding: 1em;
  text-align: center;
}

.group-thumbs{

  margin:1em 0 2em;
  padding-bottom:1em;
  @include clearfix;


  img{
    @include grids(6);
    line-height:0;
    margin-bottom:1em;
    @include opacity(0.75);
    cursor:pointer;

    &:last-child{
      margin-bottom:1em;
    }

    &:hover,
    &.cycle-pager-active{
      @include opacity(1);
    }
  }

}

#block-locale-language{
  padding-right:0;
  position:absolute;
  top:$page-gutter;
  right:$page-gutter/2;

  @include breakpoint($breakpoint_medium){
    right:$page-gutter;
  }

  & > ul{
    display:block;
    text-align:right;
    margin:0;
    line-height:1;

    @include breakpoint($breakpoint_medium){
      padding:0;
    }

    li{
      list-style:none;
      display:inline-block;
      text-transform: uppercase;
      color:lighten(#000,75%);

      @include breakpoint($breakpoint_medium){

      }

      &.first{
        &:after{
          content:'·';
          display: inline-block;
          padding:0 0em 0 0.3em;
        }
      }
      a{
        text-decoration:none;
        text-transform: uppercase;
        color:lighten(#000,75%);

      }

      &.active,
      &:hover{
        a{
          text-decoration: underline;
          //color:lighten(#000,50%);
        }
      }
    }

  }
}

#block-bean-info-block {
  background-color: $color-primary;
  color: $color-white;
  padding: 1em;
  margin: 0;
  cursor: pointer;

  .contextual-links-wrapper {
    display: none !important;
  }

  @include breakpoint($breakpoint_mobile) {
    padding: 2em;
  }

  h2 {
    color: $color-white;
    font-weight: bold;
    font-size: 1em;
    margin: 0;

    &::before {
      display: none;
    }
  }

  .block__title {
     display: none;
  }

  .field-name-field-popup-text {
    display: none;
  }
}

.mfp-wrap {
  .mfp-close {
    color: $color-primary;
    font-family: inherit;
    background-image: url("../images/icon-close.svg");
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center;
    top: 1rem;
    right: 1rem;

    &:active {
      top: 1rem;
    }
  }

  .field-name-field-popup-text {
    color: $color-primary;
    position: relative;
    margin:0 auto;
	  max-width: calc(#{$page-width} - 4rem);
    background-color: $color-white;
    padding: 2em;

    h2 {
      font-weight: bold;
    }
  }
}

#block-block-2 + #block-bean-info-block  {
  margin-top: -1em;

  @include breakpoint($breakpoint_mobile) {
    margin-top: -2em;
  }
}